@import "../styles/variables.scss";

.cards {
  display: flex;
  flex-flow: row wrap;

  .card {
    width: 380px;
    margin-right: 1.5rem;
  }

  @media (max-width: 480px) {
    .card {
      max-width: none;
      margin-right: 0;
    }
  }
}
