$primary: #1b3967;
$black: #1e3148;
$white: #fff;
$gray: #ddd;
$error: #f96058;

$header-height-desktop: 100px;
$header-height-mobile: 76px;
$footer-height-desktop: 231px;
$footer-height-mobile: 231px;

$container-max-width: 1200px;
$container-horizontal-padding: 20px;

$tablet: 768px;
$mobile: 480px;
